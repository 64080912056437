import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75')
];

export const server_loads = [0,3];

export const dictionary = {
		"/": [~4],
		"/(web)/about": [10],
		"/authenticated/social/posts": [34],
		"/(web)/contact": [~11],
		"/(auth)/error": [5],
		"/(web)/games": [12,[2]],
		"/(web)/games/simongame": [13,[2]],
		"/(web)/games/top-scores": [14,[2]],
		"/(auth)/login": [6],
		"/(auth)/logout": [7],
		"/(web)/market": [15],
		"/(web)/privacy": [~16],
		"/(web)/shop": [~17],
		"/(auth)/signin": [8],
		"/(auth)/signout": [9],
		"/(web)/terms": [~18],
		"/(web)/testing": [~19],
		"/(web)/testing/authorize": [~20],
		"/(web)/testing/products": [~21],
		"/users/admin": [~35],
		"/users/admin/customers": [36],
		"/users/admin/customers/create-customer-profile": [~37],
		"/users/admin/customers/reports": [~38],
		"/users/admin/customers/reports/account-updater-job-details": [~39],
		"/users/admin/customers/reports/account-updater-job-summary": [~40],
		"/users/admin/customers/reports/batch-statistics": [~41],
		"/users/admin/customers/reports/customer-profile-transaction-list": [~42],
		"/users/admin/customers/reports/merchant-details": [~43],
		"/users/admin/customers/reports/settled-batch-list": [~44],
		"/users/admin/customers/reports/transaction-details": [~45],
		"/users/admin/customers/reports/transaction-list": [~46],
		"/users/admin/customers/reports/unsettled-transaction-list": [~47],
		"/users/admin/customers/suite": [~48],
		"/users/admin/customers/suite/accept-payment-transaction": [~49],
		"/users/admin/dashboard": [~50],
		"/users/user/account": [~51,[3]],
		"/users/user/account/addresses": [~52,[3]],
		"/users/user/account/addresses/delete": [~53,[3]],
		"/users/user/account/addresses/edit": [~54,[3]],
		"/users/user/account/billing": [~55,[3]],
		"/users/user/account/billing/invoices": [~56,[3]],
		"/users/user/account/billing/notifications": [~57,[3]],
		"/users/user/account/billing/paymentmethods": [~58,[3]],
		"/users/user/account/billing/profile": [~59,[3]],
		"/users/user/account/billing/subscriptions": [~60,[3]],
		"/users/user/account/cart": [~61,[3]],
		"/users/user/account/company": [~62,[3]],
		"/users/user/account/favorites": [~63,[3]],
		"/users/user/account/orders": [~64,[3]],
		"/users/user/account/preferences": [~65,[3]],
		"/users/user/account/resources": [~66,[3]],
		"/users/user/account/support": [~67,[3]],
		"/users/user/dashboard": [~68,[3]],
		"/users/user/dashboard/posts": [~69,[3]],
		"/users/user/dashboard/profile": [70,[3]],
		"/users/user/dashboard/social": [~71,[3]],
		"/users/user/dashboard/social/posts/create": [72,[3]],
		"/users/user/dashboard/social/posts/drafts": [~73,[3]],
		"/users/user/dashboard/social/posts/post/[id]": [~74,[3]],
		"/users/user/intro": [~75,[3]],
		"/(web)/websolutions": [22],
		"/(web)/websolutions/analytics": [23],
		"/(web)/websolutions/design": [24],
		"/(web)/websolutions/design/logo-creation": [25],
		"/(web)/websolutions/design/product-page": [26],
		"/(web)/websolutions/design/static-blog": [27],
		"/(web)/websolutions/design/storefront": [28],
		"/(web)/websolutions/development": [29],
		"/(web)/websolutions/hosting": [30],
		"/(web)/websolutions/hosting/info/cpanel": [31],
		"/(web)/websolutions/hosting/info/mysql": [32],
		"/(web)/websolutions/hosting/info/webmail": [33]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';